<template>
  <div>
    <h2 class="h4">My Account</h2>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="profile-tab"
          data-toggle="tab"
          href="#profile"
          role="tab"
          aria-controls="profile"
          aria-selected="true"
          >Profile</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="security-tab"
          data-toggle="tab"
          href="#security"
          role="tab"
          aria-controls="security"
          aria-selected="false"
          >Security</a
        >
      </li>
    </ul>
    <div class="tab-content bg-white" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <form @submit.prevent="updateAvatar" class="mb-5">
          <div>
            <img :src="avatar" :alt="user.name" style="height: 100px" />
            <a href="#" class="text-black ml-3" @click.prevent="selectAvatar"
              >Change</a
            >
            <input
              type="file"
              style="display: none"
              id="select-avatar"
              ref="selected_avatar"
              @change="uploadAvatar"
            />
          </div>
        </form>
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-md-6 mb-4">
              <p>Email: {{ user.email }}</p>
            </div>
            <div class="col-md-6 mb-4">
              <p>Joined: {{ user.joined }}</p>
            </div>
            <div class="col-md-6 mb-4">
              <p>Participation: {{ user.comments }}</p>
            </div>
            <div class="col-md-6 mb-4">
              <p>Level: {{ user.level }}</p>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  v-model="user.name"
                  placeholder="Name"
                  required
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Phone Number</label>
                <input
                  type="text"
                  v-model="user.phone_number"
                  placeholder="Phone Number"
                  required
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-12">
              <button class="btn btn-primary btn-sm">Update</button>
            </div>
          </div>
        </form>
      </div>
      <div
        class="tab-pane fade"
        id="security"
        role="tabpanel"
        aria-labelledby="security-tab"
      >
        <form @submit.prevent="changePassword">
          <div class="form-group">
            <label>Enter your current password</label>
            <input
              type="password"
              placeholder="Current Password"
              v-model="password.current"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <label>Select a new password</label>
            <input
              type="password"
              placeholder="New Password"
              v-model="password.password"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <label>Confirm your new password</label>
            <input
              type="password"
              placeholder="Confirm Password"
              v-model="password.password_confirmation"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <button class="btn btn-primary btn-sm">Update Password</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: this.$store.getters.getAuthUser,
      password: {},
    };
  },

  methods: {
    selectAvatar() {
      document.getElementById("select-avatar").click();
    },

    uploadAvatar() {
      let data = new FormData();
      data.append("avatar", this.$refs.selected_avatar.files[0]);

      this.$axios
        .post("/api/v1/admin/my-account/avatar", data, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.$store.dispatch("setAuthUser", response.data.user);
        });
    },

    submit() {
      let data = {
        name: this.user.name,
        phone_number: this.user.phone_number,
      };

      this.$axios.post("/api/v1/admin/my-account/profile", data).then(() => {
        this.$toasted.show("Your account has been updated", { duration: 5000 });
      });
    },

    changePassword() {
      this.$axios
        .post("/api/v1/admin/my-account/security", this.password)
        .then(() => {
          this.$toasted.show("Your account has been updated", {
            duration: 5000,
          });
        })
        .catch(() => {
          this.$toasted.show("Invalid current password", { duration: 10000 });
        });
    },
  },

  computed: {
    avatar() {
      return this.$store.getters.getAuthUser.avatar;
    },
  },
};
</script>
